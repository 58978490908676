import { Component, OnInit } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { ActivatedRoute } from '@angular/router';
import Swal from 'sweetalert2';

import * as firebase from 'firebase/app';
import { AlertViewService } from 'src/app/services/alert-view.service';
import { first, map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { Project } from 'src/app/model';
import { getLangFromComponent } from 'src/app/lang/logic';
import { ComponentLabel } from 'src/app/lang/dictionary';

@Component({
  selector: 'app-project-list',
  templateUrl: './project-list.component.html',
  styleUrls: ['./project-list.component.css'],
})
export class ProjectListComponent implements OnInit {
  public lang = getLangFromComponent(ComponentLabel.projectlist);

  public items: Observable<Project[]>;

  constructor(
    private activatedRoute: ActivatedRoute,
    private db: AngularFirestore,
    private alertService: AlertViewService
  ) {
    this.items = this.db
      .collection<Project>('projects')
      .snapshotChanges()
      .pipe(
        map((actions) => {
          return actions
            .map((action) => {
              const data = action.payload.doc.data();

              return data.isOpened
                ? {
                    ...data,
                    createdAt: (data?.createdAt as any)?.toDate() ?? null,
                  }
                : null;
            })
            .filter((x) => x !== null);
        })
      );
  }

  getDate(date: Date | null): string {
    if (date === null) {
      return '';
    }
    return (
      date.getFullYear() + '/' + (date.getMonth() + 1) + '/' + date.getDate()
    );
  }

  deleteProject(id) {
    alert('誤操作防止のため、プロジェクトの削除ボタンの機能は無効化しています');
    return;

    this.alertService.showYesNoAlertDelete('プロジェクト').then((ret) => {
      if (ret) {
        firebase.default.firestore().collection('projects').doc(id).delete();
      }
    });
  }

  async showProject(p: Project) {
    const { value: formValues } = await Swal.fire({
      title: this.lang.show('プロジェクトの情報'),
      html: `<div style="display: grid; grid-template-columns: 5.5rem 1fr;  grid-row-gap: 2rem;"">
        <div class="padding-vertical">${this.lang.show(
          'プロジェクトの名称'
        )}</div><div class="padding-vertical">${p.name}</div>
        <div class="padding-vertical">${this.lang.show(
          '管理画面コード'
        )}</div><div class="padding-vertical">${p.companyCode}</div>
        <div class="padding-vertical">${this.lang.show(
          '作成日'
        )}</div><div class="padding-vertical">${this.getDate(p.createdAt)}</div>
        </div>`,
      focusConfirm: false,
      showCancelButton: false,
      preConfirm: () => {
        return {};
      },
    });
  }

  async getFirstUnopenedProject(): Promise<Project | null> {
    const projects: Project[] = (
      await firebase.default
        .firestore()
        .collection('projects')
        .orderBy('name')
        .get()
    ).docs
      .map((doc) => {
        return {
          ...(doc.data() as Project),
          createdAt: (doc.data().createdAt as any)?.toDate() ?? null,
        };
      })
      .filter((x) => x.isOpened !== true);

    if (projects.length === 0) {
      return null;
    }

    console.log(projects);

    return {
      ...projects[0],
      id: projects[0].id,
    } as Project;
  }

  async showAddProjectAlert() {
    const checkFirstProject = async () => {
      const proj = await this.getFirstUnopenedProject();
      if (proj === null) {
        alert(this.lang.show('空のプロジェクトがありません'));
        return null;
      }

      return proj;
    };

    if (checkFirstProject() === null) {
      return;
    }

    const { value: formValues } = await Swal.fire({
      title: this.lang.show('プロジェクトを登録'),
      html: `<div>
        <div style="padding-top: 2rem;">${this.lang.show(
          'プロジェクトの名前'
        )}</div><div><input id="swal-name" class="swal2-input" value=""></div>
        <div style="padding-top: 2rem;">${this.lang.show(
          '管理画面コード'
        )}</div><div><input id="swal-companyCode" class="swal2-input" value=""></div>
        </div>`,
      focusConfirm: false,
      showCancelButton: true,
      preConfirm: async () => {
        const getValueById = (id: string): string =>
          document.getElementById(id)['value'];

        const data = {
          name: getValueById('swal-name'),
          companyCode: getValueById('swal-companyCode'),
          createdAt: new Date(),
          isOpened: true,
        };

        if (data.name.length === 0) {
          alert(this.lang.show('プロジェクトの名称の項目が未入力です'));
          return false;
          /*
          } else if (data.domainName.length === 0) {
            alert("ドメインの名称の項目が未入力です");
            return false;
          */
        } else if (
          data.companyCode.length !== 4 &&
          isNaN(Number(data.companyCode))
        ) {
          alert(
            this.lang.show('管理画面コードは4桁の数字でなければなりません')
          );
          return false;
        }

        if (
          (
            await firebase.default
              .firestore()
              .collection('projects')
              .where('companyCode', '==', data.companyCode)
              .get()
          ).docs.length > 0
        ) {
          alert(this.lang.show('管理画面コードが重複しています'));
          return false;
        }

        return data;
      },
    });

    if (formValues) {
      const firstProject = await checkFirstProject();
      const updateDocument: Project = {
        ...firstProject,
        ...formValues,
      };

      await firebase.default
        .firestore()
        .collection('projects')
        .doc(firstProject.id)
        .set(updateDocument, { merge: true });
      this.alertService.showLoading();
      setTimeout(() => {
        this.alertService.dismiss();
        Swal.fire(this.lang.show('プロジェクトの登録を完了しました'));
      }, 6000);
    }
  }

  ngOnInit(): void {}
}
