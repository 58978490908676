// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  firebase: {
    apiKey: "AIzaSyA_NHJf7B_Y2RPs-vRoIVsiIfcDYnx7K70",
    authDomain: "jmar-health-care06-ada5c.firebaseapp.com",
    databaseURL: "https://jmar-health-care06-ada5c.firebaseio.com",
    projectId: "jmar-health-care06-ada5c",
    storageBucket: "jmar-health-care06-ada5c.appspot.com",
    messagingSenderId: "65395979885",
    appId: "1:65395979885:web:6c87118d5ae27f97f9f164",
    measurementId: "G-2VM3HY0HW2"
  },
  debug: false
};




/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
