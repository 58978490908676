import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Session } from 'src/app/class/session/session';
import { User } from 'src/app/class/user/user';

import { SessionService } from 'src/app/service/session.service';
import { Location } from '@angular/common';
import { NavigationEnd, Router } from '@angular/router';
import { map } from 'rxjs/operators';
import { ChatMessage } from 'src/app/class/chat-message/chat-message';
import { Patient } from 'src/app/class/patient/patient';
import { AngularFirestore } from '@angular/fire/firestore';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import * as firebase from 'firebase/app';
import { AlertViewService } from 'src/app/services/alert-view.service';
import { Storage } from '@ionic/storage-angular';
import { getLangFromComponent } from 'src/app/lang/logic';
import { ComponentLabel } from 'src/app/lang/dictionary';

@Component({
  selector: 'app-default-header',
  templateUrl: './default-header.component.html',
  styleUrls: ['./default-header.component.css']
})
export class DefaultHeaderComponent implements OnInit {
  lang = getLangFromComponent(ComponentLabel.defaultheader);

  user: User;
  showBackKey = false;
  showSearch = false;
  showUnReadBadgeForPatientList = false;
  showUnReadBadgeForPatientListTalk = false;
  searchText: string;
  @Output() onValueChanged = new EventEmitter<any>();

  public patients: Observable<Patient[]>;




  constructor(
    private session: SessionService,
    private location: Location,
    private router: Router,
    private db: AngularFirestore,
    private alertViewService: AlertViewService,
    private localStorage: Storage,

  ) {

    // ここにおいてよいか？？
    this.session.checkLogin();

    this.user = this.session.session.user;
    this.session // 追加
      .sessionState
      .subscribe(data => {
        if (environment.debug) {
          console.log(data);
        }
        this.user = data.user;
        this.putLocalStorageText();


        this.patients = db
          .collection<Patient>('patients', ref => { return ref.where('unReadMembers', 'array-contains', this.user.uid); })
          .snapshotChanges()
          .pipe(
            map(actions => actions.map(action => {
              // 日付をセットしたコメントを返す
              const data = action.payload.doc.data() as Patient;
              const patientData = new Patient(
                data.patient_id,
                data.name,
                data.avatar,
                data.members,
                data.clinic_id,
                data.isboardroom,
                data.disease_name,
                data.birthday,
                data.unReadMembers
              );


              if (data.isboardroom) {
                this.showUnReadBadgeForPatientList = true;
              } else {
                this.showUnReadBadgeForPatientListTalk = true;
              }
              return patientData;
            })));

      });
    if (environment.debug) {
      console.log(this.user);
    }
    this.doesShowBackKey(this.location.path(true));
    this.doesShowSerch(this.location.path(true));









  }


  shouldShowPrimary(str) {
    const path = this.location.path(true);

    if (
      path.indexOf(str) != -1
    ) {
      return true;
    } else {
      return false;
    }
  }

  shouldShowUnReadBadge(patients) {
    if (patients == undefined || patients.length == 0) { return false };
    return this.showUnReadBadgeForPatientListTalk || this.showUnReadBadgeForPatientList;
  }

  ngOnInit(): void {
    if (environment.debug) {
      console.log(this.user);
    }
  }

  doesShowBackKey(path) {
    if (
      path.indexOf("map") != -1 ||
      path.indexOf("item-detail-chart") != -1
    ) {

      this.showBackKey = true;
    }
  }

  doesShowSerch(path) {
    if (
      path.indexOf("item-detail") != -1 ||
      path.indexOf("talk-list") != -1 ||
      path.indexOf("patient-list") != -1
    ) {

      this.showSearch = true;
    }
  }



  logout(): void {  // 追加
    this.session.logout();
  }

  back() {
    this.location.back();
  }

  textUpdated(event) {
    console.log(event)
    if (this.location.path(true).indexOf("item-detail") != -1) {
      this.localStorage.set(this.user.uid + "item-detail", this.searchText);
    } else {
      this.localStorage.set(this.user.uid + "talk-list", this.searchText);
    }

    this.onValueChanged.emit(this.searchText);
  }


  putLocalStorageText() {
    if (this.location.path(true).indexOf("item-detail") != -1) {
      this.localStorage.get(this.user.uid + "item-detail").then((text) => {
        this.searchText = text;
        this.textUpdated(undefined);
      })
    } else {
      this.localStorage.get(this.user.uid + "talk-list").then((text) => {
        this.searchText = text;
        this.textUpdated(undefined);
      })
    }

  }

}
